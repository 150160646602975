import React, { Component, useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTags from "react-meta-tags"
import moment from 'moment'
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import Swal from "sweetalert2"
import { getDecryptedItem } from '../../util';
import LetteredAvatar from 'react-lettered-avatar';
import {
  FormGroup,
  Label,
  Input,
  CardBody,
  Card,
  Col,
  Container,
  CardTitle,
  Button,
  Row,
  Modal,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"
import instance from '../../config'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlus,
  faSearch,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faPaperPlane,
  faBuilding,
  faUser,
  faCalendar,
  faCircle,
  faClipboard,
  faBell,
} from "@fortawesome/free-solid-svg-icons"
import ModalTarea from "pages/Componentes/ModalTarea"
import ReactTooltip from "react-tooltip";
import ListarCliente from "pages/Menus/Cliente/Componentes/Listar"
const jsonwebtoken = require('jsonwebtoken');
const baseUrl = process.env.REACT_APP_API_URL
var arrayTareas = [];
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
const Tareas = props => {
  const param = useParams();
  let token = param.token;
  var FuncionGetToken = async () => {
    if (token) {

      const SECRET_KEY_JWT = "secretkey5353";
      const payload = await jsonwebtoken.verify(token, SECRET_KEY_JWT);
      if (!payload) {
        return res.status(401).send('Unauhtorized Request');
      }
      else {


        let tarea = payload["payload"]["tarea"];

        let RolId = getDecryptedItem("RolID")
        var postData = {
          nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
        }
        await instance
          .post("/Tarea/Listar", postData)
          .then(response => {
            setData(response.data)


            for (let i = 0; i < response.data.length; i++) {

              if (response.data[i]["nTar_Id"] == tarea["nTar_Id"]) {

                Acciones(response.data[i], "VerDetalle");
              }
            }
          })
          .catch(error => {

          })

      }
    }
    else {

    }
  }

  const [checked, setStateChecked] = useState(false)
  const [modalInsertar, setModalInsertar] = useState(false)
  const [modalInsertarlista, setModalInsertarlista] = useState(false)
  const [ttlModalLista, setModalLista] = useState("Nueva Lista")
  const [modalActualizar, setModalActualizar] = useState(false)
  const [modalVer, setModalVer] = useState(false)
  const [modalFiltro, setModalFiltro] = useState(false)
  const [data, setData] = useState([])
  const [dataEstado, setDataEstado] = useState([])
  const [dataParticipantes, setDataParticipantes] = useState([])
  const [dataSelect, setDataSelect] = useState([])
  const [dataTipoTarea, setDataTipoTarea] = useState([])
  const [dataEstadoTarea, setDataEstadoTarea] = useState([])
  const [dataCategoriaTarea, setDataCategoriaTarea] = useState([])
  const [dataRecordarTarea, setDataRecordarTarea] = useState([])
  const [dataLista, setDatalista] = useState([])
  const [dataCliente, setDataCliente] = useState([])
  const [dataCaso, setDataCaso] = useState([])
  const [dataComboUsuario, setDataComboUsuario] = useState([])
  const [getDefaultParticipantes, setDefaultParticipantes] = useState()
  const [GetParticipantes, setParticipantes] = useState([])
  const [modalActualizarMasivo, setModalActualizarMasivo] = useState(false)
  const [modalCambiarEstado, setModalCambiarEstado] = useState(false)
  const [estadoInterno, SetEstadoInterno] = useState([])
  const [modalDesasociarParticipantes, setModalDesasociarParticipantes] = useState(false)
  const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
  const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)
  const [hiddenLblFlujo, setHiddenLblFlujo] = useState(true)
  const [blockBtnUPD, setBlockBtnUPD] = useState(false)
  const [validData, setValidData] = useState(false)
  const [getTareas, setTareas] = useState({
    nTar_Id: "",
    nCas_Id: "",
    cTar_Tab_Tipo: "",
    cTar_Tab_Categoria: "",
    cTar_Titulo: "",
    nUsu_Asignado: 1,
    nUsu_Relacionado: 1,
    dTar_Fec_Vence: "",
    tTar_Hora_Vence: "",
    tTar_Hora_Vence2: data.tTar_Hora_Vence2,
    cTar_tab_recordar: "",
    bTar_Estado: "1",
    cEstadoTarea: "MT01",
    cCas_Cod_Externo: "",
    cCas_Cod_Interno: "",
    nCli_Id: "",
    nTar_Duracion: '1',
    nTar_Prioridad: "",
    nTar_TipoDias: '1',
    cfFlu_Nombre: '',
    cfFas_Nombre: ''
  })



  const arrayWithColors = ['#ff7f50', '#87cefa', '#da70d6', '#32cd32', '#6495ed',
    '#ff69b4', '#ba55d3', '#cd5c5c', '#ffa500', '#40e0d0'];
  const [chekedValor1, setchekedValor1] = useState(false)
  const [chekedValor2, setchekedValor2] = useState(false)
  const [chekedValor3, setchekedValor3] = useState(true)
  const [chekedValor4, setchekedValor4] = useState(false)
  const [chekedValor5, setchekedValor5] = useState(false)
  const [Valor1, setValor1] = useState(true)
  const [Valor2, setValor2] = useState(true)
  const [Valor3, setValor3] = useState(false)
  const [Valor4, setValor4] = useState(true)
  const [Valor5, setValor5] = useState(true)
  const [getListas, setListas] = useState({
    nLis_Id: null,
    nTar_Id: "",
    cLis_Nombre: "",
    nLis_Estado: ""
  })
  const [getFiltro, setFiltro] = useState({
    nCas_Id: null,
    cTar_Tab_Tipo: "TT01",
    cTar_Tab_Categoria: null,
    FechaInicial: ano + "-" + "01" + "-" + "01",
    FechaFin: ano + "-" + mes + "-" + dia,
    nCli_Id: null,
    nUsu_ID: null,
  })
  const [getUsuario, setUsuario] = useState({
    bUsu_Activo: 1,
    cUsu_Fono: "",
    cUsu_Login: "",
    cUsu_Nombres: "",
    cUsu_Pass: "",
    cUsu_email: "",
    nRol_ID: 1,
    nRol_ID2: "",
    ImgBinary: "",
    nUsu_ID: ""
  })
  const [getErrores, setErrores] = useState({});
  const Validacion = (form) => {
    let errors = {};
    let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumero = /^[0-9]+$/;
    if (form.nCas_Id == 0) {
      errors.nCas_Id = "(*)"
    }
    if (form.cTar_Tab_Categoria == 0) {
      errors.cTar_Tab_Categoria = "(*)"
    }
    if (!form.cTar_Titulo.trim()) {
      errors.cTar_Titulo = "(*)"
    }
    if (form.cTar_tab_recordar == 0) {
      errors.cTar_tab_recordar = "(*)"
    }
    if (form.cTar_Tab_Tipo == 0) {
      errors.cTar_Tab_Tipo = "(*)"
    }
    return errors;
  }
  const handleBlur = e => {
    handleChange(e)
    const { name, value } = e.target;
    if (name == "tTar_Hora_Vence") {
      if (value.length == 0) {
        const now = new Date(); // Obtener la fecha y hora actual
        let hours = now.getHours(); // Obtener solo la hora actual

        if (hours < 10) {
          hours = "0" + hours
        }
        const newValue = hours + ":00";
        setTareas((prevState) => ({
          ...prevState,
          [name]: newValue,
        }));
      }
    }


    setErrores(Validacion(getTareas))

  }
  const FuncionValidarFormulario = async () => {




    if (!getTareas.nCas_Id || !getTareas.cTar_Tab_Categoria || !getTareas.cTar_Titulo || !getTareas.cTar_tab_recordar || !getTareas.cTar_Tab_Tipo) {
      setValidData(true);
      Swal.fire({
        title: "Mensaje",
        text: "Faltan completar datos",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
    } 
    else {
      FuncionActualizarTarea()
    }
  }
  const handleChangeFiltro = e => {

    if (chekedValor1 == true || chekedValor2 == true || chekedValor3 == true || chekedValor4 == true || chekedValor5 == true) {
      setFiltro({
        ...getFiltro,
        [e.target.name]: e.target.value
      })
      if (e.target.name == "cTar_Tab_Tipo") {

        if (e.target.value == "Todos") {
          FuncionListarTareas()
        } else {
          FuncionFiltroTareas(e.target.value)
        }
      }

      if (e.target.name == "nCli_Id") {
        FuncionListarCasos(e.target.value)
      }

    } else {
      setFiltro({
        ...getFiltro,
        [e.target.name]: null
      })
    }

  }
  const handleChangeValor1 = e => {
    setchekedValor1(!chekedValor1)
    if (chekedValor1 == true) {
      setFiltro({
        ...getFiltro,
        nCli_Id: null
      })
      setValor1(true)
    } else {
      var Valor = getFiltro.nCli_Id

      setFiltro({
        ...getFiltro,
        nCli_Id: Valor
      })
      setValor1(false)
    }
  }
  const handleChangeValor2 = e => {
    setchekedValor2(!chekedValor2)
    if (chekedValor2 == true) {
      setFiltro({
        ...getFiltro,
        nCas_Id: null
      })
      setValor2(true)
    } else {
      var Valor = getFiltro.nCas_Id

      setFiltro({
        ...getFiltro,
        nCas_Id: Valor
      })
      setValor2(false)
    }
  }
  const handleChangeValor3 = e => {
    setchekedValor3(!chekedValor3)
    if (chekedValor3 == true) {
      setFiltro({
        ...getFiltro,
        cTar_Tab_Tipo: null
      })
      setValor3(true)
    } else {
      var Valor = getFiltro.cTar_Tab_Tipo

      setFiltro({
        ...getFiltro,
        cTar_Tab_Tipo: Valor
      })
      setValor3(false)
    }
  }
  const handleChangeValor4 = e => {
    setchekedValor4(!chekedValor4)
    if (chekedValor4 == true) {
      setFiltro({
        ...getFiltro,
        cTar_Tab_Categoria: null
      })
      setValor4(true)
    } else {
      var Valor = getFiltro.cTar_Tab_Categoria

      setFiltro({
        ...getFiltro,
        cTar_Tab_Categoria: Valor
      })
      setValor4(false)
    }
  }
  const handleChangeValor5 = e => {
    setchekedValor5(!chekedValor5)
    if (chekedValor5 == true) {
      setFiltro({
        ...getFiltro,
        nUsu_ID: null
      })
      setValor5(true)
    } else {
      var Valor = getFiltro.nUsu_ID

      setFiltro({
        ...getFiltro,
        nUsu_ID: Valor
      })
      setValor5(false)
    }
  }
  const ReloadModalListar = async () => {

    setModalInsertar(!modalInsertar)
    FuncionListarTareas()
    FuncionListarTareasParticipantes()
  }

  const abrirCerrarModal = Modal => {
    if (Modal == 1) {
      if (!modalInsertar == false) {
        Swal.fire({
          html: "¿Seguro que desea salir sin guardar los datos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(result => {
          if (result.isConfirmed) {
            setModalInsertar(!modalInsertar)
          }
        })
      } else {
        setModalInsertar(!modalInsertar)
      }
    } else if (Modal == 2) {
      setModalActualizar(!modalActualizar)
    }
    else if (Modal == 3) {
      setModalVer(!modalVer) /////////// AQUI SE LLAMA AL MODAL VER TAREA !
    }
    else if (Modal == 4) {
      setModalFiltro(!modalFiltro)
    }
    else if (Modal == 5) {
      setModalActualizarMasivo(!modalActualizarMasivo)
      setParticipantes([]);
    }
    else if (Modal == 6) {

      setModalCambiarEstado(!modalCambiarEstado)
    }
    else if (Modal == 7) {
      setModalDesasociarParticipantes(!modalDesasociarParticipantes)
      setParticipantes([]);
      
      FuncionListarComboUsuarioAll();
    }
    else if (Modal == 8) {

      setListas({})
      setModalLista("Nueva Lista");
      setModalInsertarlista(!modalInsertarlista)
    }
  }
  const handleChange = e => {
    const { name, value } = e.target
    setTareas(prevState => ({
      ...prevState,
      [name]: value,
    }))

    setListas(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }


  const handleChangeSelect = e => {
    let target = e.target
    let name = target.name
    let value = Array.from(e.target.selectedOptions, option => option.value)
    setTareas({
      [name]: value,
    })
  }
  const FuncionListarTareas = async () => {
    let RolId = getDecryptedItem("RolID")
    var postData = {
      nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
    }
    await instance
      .post("/Tarea/Listar", postData)
      .then(response => {

        setData(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarVerificadoEstado = async () => {
    await instance
      .post("/Tareas/Verificar/Estado")
      .then(response => {
        response.data.map((row, i) => {
          if (row.dias < 0) {
            FuncionActualizarVerificadoEstado(row.nTar_Id)
          } else {
          }
        })
        FuncionListarTareas()
      })
      .catch(error => {

      })
  }

  const FuncionListarTareasParticipantes = async (_nTar_Id) => {
    await instance
      .post("/Tareas/Participantes")
      .then(response => {
        setDataParticipantes(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionActualizarVerificadoEstado = async (_nTar_Id) => {
    var postData = {
      nTar_Id: _nTar_Id,
    }
    await instance
      .post("/Tareas/Actualizar/Estado", postData)
      .then(response => {
      })
      .catch(error => {

      })
  }

  const FuncionActualizarTarea = async () => {

    var postData = {
      nTar_Id: getTareas.nTar_Id,
      nCas_Id: getTareas.nCas_Id,
      cTar_Tab_Tipo: getTareas.cTar_Tab_Tipo,
      cTar_Tab_Categoria: getTareas.cTar_Tab_Categoria,
      cTar_Titulo: getTareas.cTar_Titulo,
      nUsu_Asignado: getTareas.nUsu_Asignado,
      nUsu_Relacionado: getTareas.nUsu_Relacionado,
      dTar_Fec_Vence: getTareas.dTar_Fec_Vence,
      tTar_Hora_Vence: getTareas.tTar_Hora_Vence,
      tTar_Hora_Vence2: getTareas.tTar_Hora_Vence2,
      cTar_tab_recordar: getTareas.cTar_tab_recordar,
      bTar_Estado: Number.parseInt(getTareas.bTar_Estado),
      nTar_Prioridad: parseInt(getTareas.nTar_Prioridad),
      nTar_Duracion: parseInt(getTareas.nTar_Duracion),
      nTar_TipoDias: parseInt(getTareas.nTar_TipoDias),
      nfFas_Id: parseInt(getTareas.nfFas_Id),
    }

    // Fecha de la tarea
    let fecha_tarea = getTareas.dTar_Fec_Vence + " " + getTareas.tTar_Hora_Vence;

    // Fecha actual
    let fecha_actual = moment().format('YYYY-MM-DD HH:mm:ss');

    // Parsear las fechas utilizando Moment.js
    let fechaTareaMoment = moment(fecha_tarea, 'YYYY-MM-DD HH:mm:ss');
    let fechaActualMoment = moment(fecha_actual, 'YYYY-MM-DD HH:mm:ss');

    // Comparar las fechas
    if (!fechaActualMoment.isAfter(fechaTareaMoment)) {
        console.log("La fecha actual NO es mayor que la fecha de la tarea");
        postData.cTar_Tab_Tipo = 'TT01'
    }
    else{

      Swal.fire({
        title: "Mensaje",
        text: "La fecha de vencimiento no puede ser menor a la fecha actual",
        icon: "error",
        confirmButtonText: "Aceptar",
      })

      return;

    }


    setBlockBtnUPD(true);
    await instance
      .post("/Tarea/Actualizar", postData)
      .then(async response => {
        let postData2 = {
          cAud_nombre: "Tareas",
          nAud_IdReg: postData.nTar_Id,
          nUsu_ID: getDecryptedItem("ID"),
          dAud_Fecha: null,
          nAud_Accion: "U",
          cTabCodigo: 'ME05'
        }
        await instance
          .post("/Auditoria/Insertar", postData2)
          .then(response => {
          });
        FuncionListarTareas()
        abrirCerrarModal(2)
        FuncionEliminarParticipantes(getTareas.nTar_Id);
        setTimeout(() => {
          const Participantes = GetParticipantes.map((row, i) => {
            FuncionActualizarParticipantes(getTareas.nTar_Id, row.value)
          })
          Swal.fire({
            title: "Mensaje",
            text: "Actualizado exitosamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          })
          setBlockBtnUPD(false);
        }, 800);
      })
      .catch(error => {

      })
    const categoria = dataCategoriaTarea.find(element => element.cTabCodigo == postData.cTar_Tab_Categoria);

    let objMails = {
      categoria: categoria.cTabNombre,
      cliente: getTareas.nCli_Id,
      parametro: {
        tpoTbl: 'tarea',   // tipo de tabla
        tpoAct: 'actualizar'  // insertar o actualizar
      },
      dataTarea: postData,
      arrayParticipantes: GetParticipantes
    }

    await instance
      .post("/mail/event/tarea", objMails)
      .then(res => {
      })
      .catch(error => {

      })
  }


  const FuncionEliminarTarea = async (_nTar_Id) => {
    var postData = {
      nTar_Id: _nTar_Id,
    }
    await instance
      .post("/Tarea/Eliminar", postData)
      .then(response => {
        FuncionListarTareas()
        Swal.fire({
          title: "Mensaje",
          text: "Eliminado exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
      })
      .catch(error => {

      })
  }

  const FuncionActualizarLista = async (_nTar_Id) => {
    var postData = {
      nTar_Id: _nTar_Id,
    }

    await instance
      .post("/Tareas/TareaListaListar", postData)
      .then(response => {
        setDatalista(response.data);

      })
      .catch(error => {

      })
  }

  const Acciones = (Tarea, caso) => {

    setTareas(Tarea)
    if (caso == "Editar") {

      if (Tarea.nfFlu_Id == null) {
        setHiddenLblFlujo(true)
      }
      else {
        setHiddenLblFlujo(false)
      }
      FuncionlistarLista(Tarea.nTar_Id);
      FuncionBuscarTareasParticipantes(Tarea.nTar_Id)
      Swal.fire({
        html:
          '<img id="imgr1" className="imgr" src="/static/media/logo-dark.7a9779c2.png" style="width:50px;height:auto;-webkit-animation: 3s rotate linear infinite;' +
          'animation: 3s rotate linear infinite;' +
          '-webkit-transform-origin: 50% 50%;' +
          'transform-origin: 50% 50%;" /> ' +
          '<br/><br/> Cargando...',
        showConfirmButton: false,
        allowOutsideClick: false
      });
      setTimeout(() => {
        let caso = Tarea.nCas_Id;


        FuncionListarComboUsuario(caso)
        abrirCerrarModal(2);
        Swal.close();
      }, 2000);
    }
    else if (caso == "VerDetalle") {
      Swal.fire({
        html:
          '<img id="imgr1" className="imgr" src="/static/media/logo-dark.7a9779c2.png" style="width:50px;height:auto;-webkit-animation: 3s rotate linear infinite;' +
          'animation: 3s rotate linear infinite;' +
          '-webkit-transform-origin: 50% 50%;' +
          'transform-origin: 50% 50%;" /> ' +
          '<br/><br/> Cargando...',
        showConfirmButton: false,
        allowOutsideClick: false
      });
      FuncionlistarLista(Tarea.nTar_Id);
      FuncionBuscarTareasParticipantes(Tarea.nTar_Id)
      setTimeout(() => {
        abrirCerrarModal(3)
        Swal.close();
      }, 2000);
    }
    else if(caso == "CambiarEstado 1"){ // Cambiar estado a "Aprobado" "TT02"
      CambiarEstadoTarea(Tarea, "TT02");
    }
    else if(caso == "CambiarEstado 2"){ // Cambiar estado a "Finalizado" "TT05"
      CambiarEstadoTarea(Tarea, "TT05");
    }
    else if(caso == "CambiarEstado 3"){ // Cambiar estado a "Rechazado" "TT03"
      CambiarEstadoTarea(Tarea, "TT03");
    }
    else {
      abrirCerrarModal(3)
    }
  }

  const CambiarEstadoTarea = async (tarea, estado) => {

    let _arrayTareas = [];
    _arrayTareas.push(adecuarEstructuraTarea(tarea));

    var postData = {
      arrayTareas: _arrayTareas,
      estadoInterno: estado,
    }

    await instance
      .post("/Tarea/ActualizarMasivo", postData)
      .then(response => {
        console.log(response);
        FuncionListarTareas();
        Swal.fire({
          title: "Mensaje",
          text: "Actualizado exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
        DeseleccionarTodo();
      })
      .catch(error => {
        console.log(error)
      })
  }

  function adecuarEstructuraTarea(tarea) {
    return {
      tarea: {
        nTar_Id: tarea.nTar_Id,
        nCas_Id: tarea.nCas_Id,
        cTar_Tab_Categoria: tarea.cTar_Tab_Categoria,
        cTar_Titulo: tarea.cTar_Titulo,
        nUsu_Asignado: tarea.nUsu_Asignado,
        nUsu_Relacionado: tarea.nUsu_Relacionado,
        dTar_Fec_Vence: tarea.dTar_Fec_Vence,
        tTar_Hora_Vence: tarea.tTar_Hora_Vence,
        cTar_tab_recordar: tarea.cTar_tab_recordar,
        bTar_Estado: tarea.bTar_Estado,
        nTar_Prioridad: tarea.nTar_Prioridad,
        nTar_Duracion: tarea.nTar_Duracion,
        nTar_TipoDias: tarea.nTar_TipoDias,
      }
    };
  }

  const AccionesLista = (lista) => {

    setListas(lista)
    setModalLista("Editar Lista");
    setModalInsertarlista(!modalInsertarlista)
  }

  const FuncionListarComboTipoTarea = async () => {
    var postData = {
      Prefijo: "TT",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        console.log(response.data)
        setDataTipoTarea(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboCategoriaTarea = async () => {
    var postData = {
      Prefijo: "CT",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {

        setDataCategoriaTarea(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboRecordarTarea = async () => {
    var postData = {
      Prefijo: "PE",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataRecordarTarea(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarCliente = async () => {
    let RolId = getDecryptedItem("RolID")

    var postData = {
      bCli_Tipo: null,
      nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
    }
    await instance
      .post("/Cliente/ListarCbo", postData)
      .then(response => {
        setDataCliente(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarCasos = async (_idClient) => {
    var postData = {
      idClient: _idClient,
      nUsu_Id: getDecryptedItem("ID"),
    }
    await instance
      .post("/Casos/ListarCbo", postData)
      .then(response => {
        setDataCaso(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboUsuario = async (nCas_Id) => {
    var postData = {
      nCas_Id: nCas_Id,
    }

    await instance
      .post("/Casos/FiltroUsuarioCaso", postData)
      .then(response => {

        
        setDataComboUsuario(response.data)
      })
      .catch(error => {

      })
  }

  const FuncionListarComboUsuarioAll = async () => {
    await instance
      .get("/Usuario/ListarUsuarioAll")
      .then(response => {
        console.log(response.data)
        setDataComboUsuario(response.data)

      })
      .catch(error => {
        console.log(error)
      })
  }


  const FuncionListarEstadoTarea = async () => {
    var postData = {
      Prefijo: "MT",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataEstadoTarea(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionFiltroTareas = async (_cTar_Tab_Tipo) => {
    var postData = {
      nCli_Id: parseInt(getFiltro.nCli_Id),
      nCas_Id: parseInt(getFiltro.nCas_Id),
      cTar_Tab_Tipo: _cTar_Tab_Tipo,
      cTar_Tab_Categoria: getFiltro.cTar_Tab_Categoria,
      FechaInicial: getFiltro.FechaInicial,
      FechaFin: getFiltro.FechaFin,
      nUsu_ID: getFiltro.nUsu_ID,
    }
    if (postData.cTar_Tab_Tipo == "Todos") {
      postData.cTar_Tab_Tipo = null;
    }

    await instance
      .post("/Tarea/Buscar", postData)
      .then(response => {

        setData(response.data)
      })
      .catch(error => {

      })
  }
  const handleChangeParticipantes = value => {
    if (value == null) {
    } else {

      setParticipantes(value);
    }
  };
  const FuncionBuscarTareasParticipantes = async (_nTar_Id) => {
    var postData = {
      nTar_Id: _nTar_Id,
    }
    await instance
      .post("/Tarea/Buscar/Participante", postData)
      .then(response => {
        setParticipantes(response.data);
      })
      .catch(error => {

      })
  }
  const FuncionActualizarParticipantes = async (_nTar_Id, _nUsu_ID) => {
    var postData = {
      nTar_Id: Number.parseInt(_nTar_Id),
      nUsu_ID: Number.parseInt(_nUsu_ID),
    }
    await instance
      .post("/Tarea/Insertar/Participante", postData)
      .then(response => {
        FuncionListarTareas()
        FuncionListarTareasParticipantes()
      })
      .catch(error => {

      })
  }
  const FuncionEliminarParticipantes = async (_nTar_Id) => {
    var postData = {
      nTar_Id: Number.parseInt(_nTar_Id),
    }
    await instance
      .post("/Tarea/Eliminar/Participante", postData)
      .then(response => {
      })
      .catch(error => {

      })
  }
  const SelectDetalle = async (nCas_Id, nCli_Id) => {
    localStorage.setItem('nCas_Id', nCas_Id);
    localStorage.setItem('CLIID', nCli_Id);
  }
  const restaFechas = function (f2) {
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    hoy.toLocaleDateString();
    var aFecha1 = new Date(f2);
    var aFecha2 = new Date(hoy);
    var fFecha1 = aFecha1;
    var fFecha2 = aFecha2;

    var dif = fFecha1 - fFecha2;
    var dias = Math.floor(dif / (1000 * 60 * 60 * 24));
    return dias + 1;
  }

  /**  Funcion de interactividad del boton "acciones masivas" */
  function myFunction() {
    //Añade una clase al elemento que tenga el id myDropdown
    document.getElementById("myDropdown").classList.toggle("show");
  }
  window.onclick = function (event) {
    if (!event.target.matches('.drop-button')) {
      var dropdowns = document.getElementsByClassName("dropdown-content");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        //Busca dentro de drop-content los elementos con la clase show
        if (openDropdown.classList.contains('show')) {
          //elimina la clase show de los elementos dentro de drop-content
          openDropdown.classList.remove('show');
        }
      }
    }
  }
  /*********  Seleccionar checkbox */
  const SelectCheckbox = async (tarea) => {
    var _idTarea = tarea.nTar_Id;
    var participantesTarea = [];
    var objetoTarea = {
      tarea: tarea,
      participantes: []
    }
    participantesTarea = dataParticipantes.filter(row => row.nEve_Id == _idTarea)
    objetoTarea.participantes = participantesTarea;

    if (arrayTareas.length == 0) {
      arrayTareas.push(objetoTarea);
      setStateChecked(true)
    }
    else {
      let trueFalse = false;
      for (var i = 0; i < arrayTareas.length; i++) {
        if (tarea.nTar_Id == arrayTareas[i].tarea.nTar_Id) {
          arrayTareas.splice(i, 1)
          trueFalse = true;
        }
      }
      if (i == arrayTareas.length) {
        if (trueFalse != true) {
          arrayTareas.push(objetoTarea);
        }
      }
    }
    if (arrayTareas.length == 0) {
      setStateChecked(false)
    }

  }
  /*****  Funcion actualizar masivo participantes */
  const FuncionActualizarTareaMasivo = async () => {

    var i = 0;
    for (i = 0; i < arrayTareas.length; i++) {
      var allParticipantes = [];
      if (GetParticipantes.length > 0) {
        for (var a = 0; a < GetParticipantes.length; a++) {
          allParticipantes.push(GetParticipantes[a]);
        }
        if (a == GetParticipantes.length) {
          let arrayTareaParticipantes2 = arrayTareas[i].participantes
          for (var h = 0; h < arrayTareaParticipantes2.length; h++) {
            let objParticipantes = {
              email: arrayTareaParticipantes2[h].email,
              label: arrayTareaParticipantes2[h].label,
              value: arrayTareaParticipantes2[h].value
            }
            allParticipantes.push(objParticipantes);
          }
          let hash = {};
          allParticipantes = allParticipantes.filter(o => hash[o.value] ? false : hash[o.value] = true);

        }
        /*************************************************************** */

        var getTarea = arrayTareas[i].tarea;

        var postData2 = {
          nTar_Id: Number.parseInt(getTarea.nTar_Id),
        }

        setBlockBtnUPD(true);
        await instance
          .post("/Tarea/Eliminar/Participante", postData2)
          .then(async response => {

            for (let i = 0; i < allParticipantes.length; i++) {
              var postData = {
                nTar_Id: (getTarea.nTar_Id),
                nUsu_ID: allParticipantes[i].value,
              }
              await instance
                .post("/Tarea/Insertar/Participante", postData)
                .then(async response => {

                  let participantes = [];
                  participantes.push(allParticipantes[i]);
                  let objMails = {
                    cliente: getTarea.nCli_Id,
                    parametro: {
                      tpoTbl: 'tarea',   // tipo de tabla
                      tpoAct: 'insertar'  // insertar o actualizar
                    },
                    dataTarea: getTarea,
                    arrayParticipantes: participantes
                  }

                  await instance
                    .post("/mail/event/tarea", objMails)
                    .then(res => {
                      setTimeout(() => {
                        setBlockBtnUPD(false);
                      }, 2000);
                    })
                    .catch(error => {

                    })
                  setModalActualizarMasivo(!modalActualizarMasivo);

                })
                .catch(error => {

                })
            }
            FuncionListarTareas()
          })
          .catch(error => {

          });
      }
      else {
        abrirCerrarModal(5)
      }
    }
    FuncionListarTareas()
    FuncionListarTareasParticipantes();
    setParticipantes([]);
    abrirCerrarModal(5)
    Swal.fire({
      title: "Mensaje",
      text: "Actualizado exitosamente",
      icon: "success",
      confirmButtonText: "Aceptar",
    })
  }
  /***** Funcion cambiar estado tarea */
  const handleChangeEstadointerno = e => {

    SetEstadoInterno(e.target.value);
  }
  const methodCambiarEstado = async () => {
    console.log(arrayTareas);
    var postData = {
      arrayTareas: arrayTareas,
      estadoInterno: estadoInterno,
    }

    await instance
      .post("/Tarea/ActualizarMasivo", postData)
      .then(response => {
        console.log(response);
        FuncionListarTareas();
        Swal.fire({
          title: "Mensaje",
          text: "Actualizado exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
        abrirCerrarModal(6)
        DeseleccionarTodo();
      })
      .catch(error => {
        console.log(error)
      })



  }
  /***** Funcion eliminar tarea logicamente*/
  const methodEliminarTareasLogicamente = async () => {
    var countstatusText = 0;
    Swal.fire({
      html: "¿ Seguro que desea eliminar ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(result => {
      if (result.isConfirmed) {
        setTimeout(() => {
          Swal.fire({
            html: "¿ Realmente seguro de eliminar ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si",
            cancelButtonText: "No",
          }).then(async result => {
            if (result.isConfirmed) {
              for (let i = 0; i < arrayTareas.length; i++) {
                let tarea = arrayTareas[i].tarea;
                var postData = {
                  nTar_Id: tarea.nTar_Id,
                }
                await instance
                  .post("/Tarea/Eliminar", postData)
                  .then(response => {

                    if (response.statusText == "OK") {
                      countstatusText++
                    }
                  })
                  .catch(error => {

                  })
              }
              FuncionListarTareas()
              Swal.fire({
                title: "Mensaje",
                text: ` ${countstatusText} Tareas eliminadas exitosamente!`,
                icon: "success",
                confirmButtonText: "Aceptar",
              })
              //DeseleccionarTodo();
            }
            else {
              //DeseleccionarTodo();
            }
          })
        }, 200);
      }
      else {
        //DeseleccionarTodo();
      }
    })
  }
  /** Eliminar participantes masivo */
  const methodEliminarParticipantes = async () => {


    var countstatusText = 0;
    for (let i = 0; i < arrayTareas.length; i++) {
      let tarea = arrayTareas[i].tarea;
      let nTar_Id = tarea.nTar_Id;
      for (let u = 0; u < GetParticipantes.length; u++) {
        var postData = {
          nTar_Id: nTar_Id,
          nUsu_Id: GetParticipantes[u].value
        }
        await instance
          .post("/Tarea/Participantes/EliminarParticipantes", postData)
          .then(response => {

            if (response.data.affectedRows == 1) {
              countstatusText++
            }
          })
          .catch(error => {

          });
      }
    }
    FuncionListarTareas();
    FuncionListarTareasParticipantes();
    Swal.fire({
      title: "Mensaje",
      text: ` ${countstatusText} participantes eliminados de ${arrayTareas.length} registros`,
      icon: "success",
      confirmButtonText: "Aceptar",
    });
    abrirCerrarModal(7);
  }
  function validarFecha(fecha, recordatorio, tipo) {
    var trueFalse = false;
    var label = "";
    if (tipo == "TT01") {
      var numRecordatorio = 0;
      var now = moment().format('YYYY-MM-DD');
      if (recordatorio == 'PE01') { numRecordatorio = 1 }
      if (recordatorio == 'PE02') { numRecordatorio = 2 }
      if (recordatorio == 'PE03') { numRecordatorio = 3 }
      if (recordatorio == 'PE04') { numRecordatorio = 7 }
      if (recordatorio == 'PE05') { numRecordatorio = 15 }
      //if(recordatorio=='PE06'){numRecordatorio=1}
      var fecha1 = moment(now); //Fecha del servidor
      var fecha2 = moment(fecha); //Fecha del fin
      var diferencia = fecha2 - fecha1;
      diferencia = diferencia / (1000 * 60 * 60 * 24);
      if (diferencia < 0) {
      }
      else {
        if (diferencia <= numRecordatorio) {
          label = " - " + diferencia + " dias restantes"
          return label;
        }
        else {
          return label;
        }
      }
    }
  }
  function sortJSON(data, key, orden) {
    return data.sort(function (a, b) {
      var x = a[key],
        y = b[key];
      if (orden === 'asc') {
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      }
      if (orden === 'desc') {
        return ((x > y) ? -1 : ((x < y) ? 1 : 0));
      }
    });
  }

  const datas = {
    columns: [
      {
        label: "Listas ",
        field: "cLis_Nombre",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Estado ",
        field: "estado",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },

    ],
    rows: dataLista,
  }
  const badgesData = {
    columns: [
      ...datas.columns,
      {
        label: "Acciones",
        field: "acciones",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
    ],
    rows: [
      ...datas.rows.map((row, id) => ({
        ...row,
        acciones: (
          <div>
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="dropdown-toggle">
                <i className="bx bx-dots-vertical-rounded" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => FuncionEliminarLista(0, row.nLis_Id)}>
                  Realizado
                </DropdownItem>
                <DropdownItem
                  onClick={() => FuncionEliminarLista(1, row.nLis_Id)}
                >
                  Pendiente
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ),
      })),
    ],
  }
  const badgesData2 = {
    columns: [
      ...datas.columns,
    ],
    rows: [
      ...datas.rows.map((row, id) => ({
        ...row,
      })),
    ],
  }
  const UsuariosSelect = dataComboUsuario.map((row, i) => ({
    value: row.nUsu_Id,
    label: row.cUsu_Nombres,
    color: "#556ee6",
    email: row.cUsu_email,
  }))
  const onChangeHandler = (e) => {

    if (e.target.value == "") {
      FuncionListarTareas()
    }
    else {
      var newArray = data.filter((d) => {
        return d.cTar_Titulo.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      });

      setData(newArray)
    }
  }
  const FuncionlistarLista = async (_nTar_Id) => {
    var postData = {
      nTar_Id: _nTar_Id,
    }

    await instance
      .post("/Tareas/TareaListaListar", postData)
      .then(response => {
        setDatalista(response.data);

      })
      .catch(error => {

      })
  }
  const FuncioninsertarLista = async () => {
    let _nfTar_Id = getTareas.nTar_Id;



    var postData = {
      nLis_Id: null,
      cLis_Nombre: getListas.cLis_Nombre,
      nTar_Id: _nfTar_Id
    }

    if (postData.cLis_Nombre == undefined || postData.cLis_Nombre == null || postData.cLis_Nombre == "") {
      Swal.fire({
        title: "Mensaje",
        text: "Ingrese un nombre",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
    }
    else {
      if (getListas.nLis_Id) {
        postData.nLis_Id = parseInt(getListas.nLis_Id);
        await instance
          .post("/Tareas/TareasListaActualizar", postData)
          .then(response => {
            FuncionlistarLista(_nfTar_Id);

            setModalInsertarlista(!modalInsertarlista)
            setListas({})
          })
          .catch(error => {

          })
      }
      else {
        await instance
          .post("/Tareas/ListaInsertar", postData)
          .then(response => {
            FuncionlistarLista(_nfTar_Id);

            setModalInsertarlista(!modalInsertarlista)
            setListas({})
          })
          .catch(error => {

          })
      }
    }
  }
  const FuncionEliminarLista = async (parametro, _nLis_Id) => {



    var postData = {
      nLis_Id: parseInt(_nLis_Id),
      estado: 0
    }

    if (parametro == 0) {
      postData.estado = 1 //Aprobado
    }
    else {
      postData.estado = 0 //Pendiente
    }

    await instance
      .post("/Tareas/TareasListaEliminar", postData)
      .then(response => {
        let _nfTar_Id = getTareas.nTar_Id;
        FuncionlistarLista(_nfTar_Id);

      })
      .catch(error => {

      })
  }
  const FuncionListarPermisos = async () => {
    var postData = {
      nUsu_ID: parseInt(getDecryptedItem("RolID"))
    }
    await instance
      .post("/Usuario/Acceso", postData)
      .then(response => {
        let arrayPermisos = response.data;
        const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME05");

        if (permiso.bUsu_Editar == 1) {
          setHiddenBtnEditar(false);
        }
        else {
          setHiddenBtnEditar(true);
        }
        if (permiso.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar(false);
        }
        else {
          setHiddenBtnEliminar(true);
        }
      })
      .catch(error => {

      })
  }
  const FuncionListarUsuario = async (_nUsu_ID) => {
    var postData = {
      nUsu_ID: _nUsu_ID,
    }
    await instance
      .post("/Usuario/Perfil", postData)
      .then(response => {

        setUsuario(response.data[0])
      })
      .catch(error => {

      })
  }

  const SeleccionarTodo = async () => {
    var elem = document.getElementById("selecionarMasivo")
    var i
    if (elem.checked) {
      for (i = 0; i < document.f1.elements.length; i++)
        if (document.f1.elements[i].type == "checkbox") {
          document.f1.elements[i].checked = 1
          SelectCheckbox(JSON.parse(document.f1.elements[i].name))
        }
    } else {
      for (i = 0; i < document.f1.elements.length; i++)
        if (document.f1.elements[i].type == "checkbox")
          document.f1.elements[i].checked = 0
      arrayTareas = []
    }
  }

  const DeseleccionarTodo = async () => {
    var i
    for (i = 0; i < document.f1.elements.length; i++)
      if (document.f1.elements[i].type == "checkbox")
        document.f1.elements[i].checked = 0
    arrayTareas = []
  }

  useEffect(async () => {
    let nUsu_ID = getDecryptedItem("ID")
    FuncionListarUsuario(nUsu_ID);
    FuncionListarPermisos()
    await FuncionGetToken();
    FuncionListarTareas()
    FuncionListarComboTipoTarea()       //registrado
    FuncionListarComboCategoriaTarea()  //registrado
    FuncionListarComboRecordarTarea()   //registrado
    FuncionListarCliente()
    FuncionListarCasos(null)
    FuncionListarEstadoTarea()
    let rol = getDecryptedItem("RolID");
    if (rol !== '9') {
      localStorage.removeItem('CLIID');
      localStorage.removeItem('nCas_Id');
    }
    FuncionListarTareasParticipantes();
    //FuncionListarVerificadoEstado()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        { }
        <MetaTags>
          <title>Tarea | GpsLegal</title>
        </MetaTags>
        <Container fluid>
          { }
          <Breadcrumbs title="GpsLegal" breadcrumbItem="Tareas" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div>
                    <button
                      type="button"
                      onClick={() => abrirCerrarModal(1)}
                      className="btn btn-danger waves-effect waves-light"
                      data-toggle="modal"
                      data-target="#myModal"
                      hidden={hiddenBtnEditar}
                    >
                      <i style={{ fontSize: "15px" }} className="icon-nuevo"></i>  Nueva Tarea
                    </button>{" "}
                    <button
                      type="button"
                      onClick={() => abrirCerrarModal(4)}
                      className="btn btn-danger waves-effect waves-light"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <i style={{ fontSize: "15px" }} className="icon-filtro"></i>  Filtros
                    </button>{" "}
                    <Link
                      type="button"
                      className="btn btn-danger waves-effect waves-light"
                      to="/calendar"
                    >
                      <i style={{ fontSize: "15px" }} className="icon-calendario"></i>  Calendario
                    </Link>
                    {(() => {
                      if (modalInsertar == true) {
                        return <>
                          <ModalTarea show={modalInsertar}
                            onCloseClick={() => abrirCerrarModal(1)}
                            onCloseModal={() => setModalInsertar(false)}
                            ReloadLista={() => ReloadModalListar()}
                            Titulo="Nueva Tarea"
                          />
                        </>
                      }
                    })()}
                    <Modal size="l" isOpen={modalInsertarlista}>
                      <div className="modal-header ModalHeaderColor">
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          {ttlModalLista}
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(8)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <input
                              disabled
                              className="form-control inputDown"
                              type="text"
                              placeholder="Nombre de la tarea"
                              name="cTar_Titulo"
                              value={getTareas && getTareas.cTar_Titulo}
                            />
                            <label className="col-form-label labelDown">
                              Tarea
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <input
                              className="form-control inputDown"
                              type="text"
                              placeholder="Ingrese el nombre de la lista"
                              name="cLis_Nombre"
                              value={getListas && getListas.cLis_Nombre}
                              onChange={handleChange}
                            />
                            <label className="col-form-label labelDown">
                              Nombre
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(8)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger waves-effect waves-light"
                          onClick={() => FuncioninsertarLista()}
                        >
                          Guardar
                        </button>
                      </div>

                    </Modal>
                    <Modal isOpen={modalDesasociarParticipantes}>
                      <div className="modal-header ModalHeaderColor">
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                        Desasociar participantes
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(7)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <label> Tareas seleccionadas : {arrayTareas.length}</label>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <Select
                              isMulti
                              isClearable
                              onChange={handleChangeParticipantes}
                              className="basic-multi-select"
                              placeholder="Ingrese los participantes"
                              options={UsuariosSelect}
                              defaultValue={GetParticipantes}
                            />
                            <label className="col-form-label labelDown">
                              Participantes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(7)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger waves-effect waves-light"
                          onClick={() => methodEliminarParticipantes()}
                        >
                          Guardar
                        </button>
                      </div>
                    </Modal>
                    <Modal isOpen={modalCambiarEstado}>
                      <div className="modal-header ModalHeaderColor" >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Editar tarea
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(6)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <label> Tareas seleccionadas : {arrayTareas.length}</label>
                        <div className="col-md-12">
                          <div className="col-md-3"></div>
                          <div className="col-md-6" style={{ marginTop: '30px' }}>
                            <select
                              className="form-select"
                              name="cTar_Tab_Tipo"
                              onChange={handleChangeEstadointerno}
                              value={estadoInterno}
                            >
                              <option value="00">Seleccionar...</option>
                              {dataTipoTarea.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Estado
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(6)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger waves-effect waves-light"
                          onClick={() => methodCambiarEstado()}
                        >
                          Guardar
                        </button>
                      </div>
                    </Modal>
                    <Modal isOpen={modalActualizarMasivo}>
                      <div className="modal-header ModalHeaderColor">
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Editar tareas
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(5)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <label> Tareas seleccionadas : {arrayTareas.length}</label>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <Select
                              isMulti
                              isClearable
                              onChange={handleChangeParticipantes}
                              className="basic-multi-select"
                              placeholder="Ingrese los participantes"
                              options={UsuariosSelect}
                              defaultValue={GetParticipantes}
                            />
                            <label className="col-form-label labelDown">
                              Participantes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(5)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          disabled={blockBtnUPD}
                          type="button"
                          className="btn btn-danger waves-effect waves-light"
                          onClick={() => FuncionActualizarTareaMasivo()}
                        >
                          Guardar
                        </button>
                      </div>
                    </Modal>
                    <Modal size="lg" isOpen={modalActualizar}>
                      <div
                        className="modal-header ModalHeaderColor"
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Editar tarea
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(2)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <select className="form-select inputDown" disabled="true"
                              name="nCli_Id"
                              value={getTareas && getTareas.nCli_Id}>
                              <option value="0">Seleccionar...</option>
                              {dataCliente.map((row, i) => (
                                <option key={i} value={row.nCli_Id}>
                                  {row.cCli_NombreCompleto}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Cliente
                            </label>
                          </div>
                          <div className="col-md-6">
                            <select
                              className="form-select inputDown"
                              name="nCas_Id" disabled="true"
                              value={getTareas && getTareas.nCas_Id}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCaso.map((row, i) => (
                                <option key={i} value={row.nCas_Id}>
                                  {row.cCas_Titulo}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Caso
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                              style={{ marginLeft: "5px" }}
                            >
                              Cod Interno: {getTareas.cCas_Cod_Interno}
                            </label>
                          </div>
                          <div className="col-md-6" style={{ marginLeft: "0px", paddingLeft: '0px' }}>
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                              style={{ marginLeft: "0px", paddingLeft: '0px' }}
                            >
                              Cod Externo: {getTareas.cCas_Cod_Externo}
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row" style={{ marginTop: '10px' }} hidden={hiddenLblFlujo}>
                          <div className="col-md-6">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                              style={{ marginLeft: "5px" }}
                            >
                              Flujo: {getTareas.cfFlu_Nombre} /   Fase: {getTareas.cfFas_Nombre}
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row" style={{ marginTop: '15px' }}>
                          <div className="col-md-6">
                            <select
                              className={getErrores.cTar_Tab_Categoria && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                              name="cTar_Tab_Categoria"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={getTareas && getTareas.cTar_Tab_Categoria}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCategoriaTarea.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">Categoria</label> {getErrores.cTar_Tab_Categoria && <label className="col-form-label labelDown labelRed">{getErrores.cTar_Tab_Categoria}</label>}
                          </div>
                          <div className="col-md-6">
                            <textarea
                              className={getErrores.cTar_Titulo && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                              placeholder="Ingrese el título"
                              name="cTar_Titulo"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={getTareas && getTareas.cTar_Titulo}
                              maxLength="200p"
                            ></textarea>
                            <label className="col-form-label labelDown">Titulo</label>{getErrores.cTar_Titulo && <label className="col-form-label labelDown labelRed">{getErrores.cTar_Titulo}</label>}
                          </div>
                        </div>
                        <div className="mb-3 row">
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <Select
                              isMulti
                              onChange={handleChangeParticipantes}
                              name="colors"
                              options={UsuariosSelect}
                              defaultValue={GetParticipantes}
                              className="basic-multi-select"
                              placeholder="Ingrese los participantes"
                            />
                            <label className="col-form-label labelDown">
                              Participantes
                            </label>
                          </div>
                          <div className="col-md-2" style={{ width: '90px' }}>
                            <input
                              type="text"
                              className="form-control"
                              id="nTar_Duracion"
                              name="nTar_Duracion"
                              value={getTareas && getTareas.nTar_Duracion}
                              onChange={handleChange}
                              style={{ textAlign: 'right' }}
                            />
                            <label className="col-form-label labelDown">
                              Duración
                            </label>
                          </div>
                          <div className="col-md-2" style={{ width: '150px' }}>
                            <select
                              className="form-select"
                              name="nTar_TipoDias"
                              onChange={handleChange}
                              value={getTareas && getTareas.nTar_TipoDias}
                            >
                              <option value="0">Seleccionar...</option>
                              <option value="1">Dias calendario</option>
                              <option value="2">Dias utiles</option>
                            </select>
                            <label className="col-form-label labelDown">
                              Dias
                            </label>
                          </div>

                          <div className="col-md-2" style={{ width: '150px' }}>
                            <select
                              className="form-select"
                              name="nTar_Prioridad"
                              onChange={handleChange}
                              value={getTareas && getTareas.nTar_Prioridad}
                            >
                              <option value="0">Seleccionar...</option>
                              <option value="1">Alta</option>
                              <option value="2">Mediana</option>
                              <option value="3">Baja</option>
                              <option value="4">Sin Prioridad</option>
                            </select>
                            <label className="col-form-label labelDown">
                              Prioridad
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-3">
                            <input
                              className="form-control"
                              type="date"
                              name="dTar_Fec_Vence"
                              onChange={handleChange}
                              value={getTareas && getTareas.dTar_Fec_Vence}
                            />
                            <label className="col-form-label labelDown">
                              Fecha de vencimiento
                            </label>
                          </div>
                          <div className="col-md-3">
                            <input
                              className="form-control"
                              type="time"
                              name="tTar_Hora_Vence"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={getTareas && getTareas.tTar_Hora_Vence}
                            />
                            <label className="col-form-label labelDown">
                              Hora de vencimiento
                            </label>
                          </div>
                          <div className="col-md-3">
                            <select
                              className="form-select"
                              name="cTar_tab_recordar"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={getTareas && getTareas.cTar_tab_recordar}
                            >
                              <option value="0">Seleccionar...</option>
                              {sortJSON(dataRecordarTarea, 'cTabCodigo', 'asc').map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">Recordar</label>{getErrores.cTar_tab_recordar && <label className="col-form-label labelDown labelRed">{getErrores.cTar_tab_recordar}</label>}
                          </div>
                          <div className="col-md-3">
                            <select
                              className="form-select"
                              name="cTar_Tab_Tipo"
                              onChange={handleChange}
                              value={getTareas && getTareas.cTar_Tab_Tipo}
                            >
                              <option value="00">Seleccionar...</option>
                              {dataTipoTarea.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">Estado</label> {getErrores.cTar_Tab_Tipo && <label className="col-form-label labelDown labelRed">{getErrores.cTar_Tab_Tipo}</label>}
                          </div>
                        </div>
                        <div className="mb-3 row">
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6" hidden>
                            <select
                              className="form-select"
                              name="bTar_Estado"
                              value={getTareas && getTareas.bTar_Estado}
                              onChange={handleChange}
                            >
                              <option value="00">Seleccionar...</option>
                              <option value="1">Activo</option>
                              <option value="0">No activo</option>
                            </select>
                            <label className="col-form-label labelDown">
                              Estado
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <CardBody style={{ paddingLeft: '0px', paddingBottom: '0px' }}>
                              <Col className="col-12 row" style={{ paddingRight: '0px' }}>
                                <FormGroup className="col-6" >
                                  <Label htmlFor="cardnumberInput">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-check" viewBox="0 0 16 16">
                                      <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
                                    </svg> Listas
                                  </Label>
                                </FormGroup>
                                <FormGroup className="col-6 " style={{ textAlign: 'right', paddingRight: '0px' }} hidden={true}>
                                  <button
                                    style={{ height: '30px', textAlign: 'right' }}
                                    type="button"
                                    onClick={() => abrirCerrarModal(8)}
                                    className="btn btn-danger waves-effect waves-light"
                                    data-toggle="modal"
                                    data-target="#myModal"
                                  >
                                    Nueva
                                  </button>
                                </FormGroup>
                              </Col>
                              <MDBDataTable
                                data={badgesData}
                                className="TablaListas"
                                responsiveXl
                                fixed
                                hover
                                entriesLabel="Mostrar"
                                entrylabel={"Mostrar entradas"}
                                infoLabel={["Mostrando ", "a", "de", "registros"]}
                                bordered
                                order={["age", "desc"]}
                                noRecordsFoundLabel={"No hay datos"}
                                searchLabel={"Buscar"}
                                noBottomColumns
                                paginationLabel={["Anterior", "Siguiente"]}
                                theadColor="HeadBlue"
                                searching={false}
                                paging={false}
                                info={false}
                              />
                            </CardBody>
                          </div>
                        </div>

                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(2)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          disabled={blockBtnUPD}
                          type="button"
                          onClick={() => FuncionValidarFormulario()}
                          className="btn btn-danger waves-effect waves-light"
                        >
                          Guardar
                        </button>
                      </div>
                    </Modal>


                    <Modal size="lg" isOpen={modalVer}>
                      <div
                        className="modal-header ModalHeaderColor"
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Detalle de la tarea
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(3)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <select className="form-select inputDown" disabled="true"
                              name="nCli_Id"
                              value={getTareas && getTareas.nCli_Id}>
                              <option value="0">Seleccionar...</option>
                              {dataCliente.map((row, i) => (
                                <option key={i} value={row.nCli_Id}>
                                  {row.cCli_NombreCompleto}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Cliente
                            </label>
                          </div>
                          <div className="col-md-6">
                            <select
                              className="form-select inputDown"
                              name="nCas_Id" disabled="true"
                              value={getTareas && getTareas.nCas_Id}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCaso.map((row, i) => (
                                <option key={i} value={row.nCas_Id}>
                                  {row.cCas_Titulo}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Caso
                            </label>
                          </div>
                        </div>
                        <div className="mb-1 row">
                          <div className="col-md-6">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                              style={{ marginLeft: "5px" }}
                            >
                              Cod Interno: {getTareas.cCas_Cod_Interno}
                            </label>
                          </div>
                          <div className="col-md-6" style={{ paddingLeft: "0px", marginLeft: '0px' }}>
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                              style={{ marginLeft: "0px" }}
                            >
                              Cod Externo: {getTareas.cCas_Cod_Externo}
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row" style={{ marginTop: '10px' }}>
                          <div className="col-md-6">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                              style={{ marginLeft: "5px" }}
                            >
                              Flujo: {getTareas.cfFlu_Nombre} /   Fase: {getTareas.cfFas_Nombre}
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row" style={{ marginTop: '15px' }}>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <select
                              disabled="true"
                              className="form-select"
                              name="cTar_Tab_Categoria"
                              onChange={handleChange}
                              value={getTareas && getTareas.cTar_Tab_Categoria}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCategoriaTarea.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Categoría
                            </label>
                          </div>
                          <div className="col-md-6">
                            <textarea
                              disabled="true"
                              className="form-control"
                              placeholder="Ingrese el título"
                              name="cTar_Titulo"
                              onChange={handleChange}
                              value={getTareas && getTareas.cTar_Titulo}
                            ></textarea>
                            <label className="col-form-label labelDown">
                              Título
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <Select
                              isDisabled={true}
                              isMulti
                              onChange={handleChangeParticipantes}
                              name="colors"
                              options={UsuariosSelect}
                              defaultValue={GetParticipantes}
                              className="basic-multi-select"
                              placeholder="Ingrese los participantes"
                            />
                            <label className="col-form-label labelDown">
                              Participantes
                            </label>
                          </div>
                          <div className="col-md-2" style={{ width: '90px' }} >
                            <input
                              disabled
                              type="text"
                              className="form-control"
                              id="nTar_Duracion"
                              name="nTar_Duracion"
                              value={getTareas && getTareas.nTar_Duracion}
                              onChange={handleChange}
                              style={{ textAlign: 'right' }}
                            />
                            <label className="col-form-label labelDown">
                              Duración
                            </label>
                          </div>
                          <div className="col-md-2" style={{ width: '150px' }} >
                            <select
                              disabled
                              className="form-select"
                              name="nTar_TipoDias"
                              onChange={handleChange}
                              value={getTareas && getTareas.nTar_TipoDias}
                            >
                              <option value="0">Seleccionar...</option>
                              <option value="1">Dias calendario</option>
                              <option value="2">Dias utiles</option>
                            </select>
                            <label className="col-form-label labelDown">
                              Dias
                            </label>
                          </div>

                          <div className="col-md-2" style={{ width: '150px' }} disabled={true}>
                            <select
                              disabled
                              className="form-select"
                              name="nTar_Prioridad"
                              onChange={handleChange}
                              value={getTareas && getTareas.nTar_Prioridad}
                            >
                              <option value="0">Seleccionar...</option>
                              <option value="1">Alta</option>
                              <option value="2">Mediana</option>
                              <option value="3">Baja</option>
                              <option value="4">Sin Prioridad</option>
                            </select>
                            <label className="col-form-label labelDown">
                              Prioridad
                            </label>
                          </div>

                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-3">
                            <input
                              disabled="true"
                              className="form-control"
                              type="date"
                              name="dTar_Fec_Vence"
                              onChange={handleChange}
                              value={getTareas && getTareas.dTar_Fec_Vence}
                            />
                            <label className="col-form-label labelDown">
                              Fecha de vencimiento
                            </label>
                          </div>
                          <div className="col-md-3">
                            <input
                              disabled="true"
                              className="form-control"
                              type="time"
                              name="tTar_Hora_Vence"
                              onChange={handleChange}
                              value={getTareas && getTareas.tTar_Hora_Vence}
                            />
                            <label className="col-form-label labelDown">
                              Hora de vencimiento
                            </label>
                          </div>
                          <div className="col-md-3">
                            <select
                              disabled="true"
                              className="form-select"
                              name="cTar_tab_recordar"
                              onChange={handleChange}
                              value={getTareas && getTareas.cTar_tab_recordar}
                            >
                              <option value="0">Seleccionar...</option>
                              {sortJSON(dataRecordarTarea, 'cTabCodigo', 'asc').map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Recordar
                            </label>
                          </div>
                          <div className="col-md-3">
                            <select
                              disabled="true"
                              className="form-select"
                              name="cTar_Tab_Tipo"
                              onChange={handleChange}
                              value={getTareas && getTareas.cTar_Tab_Tipo}
                            >
                              <option value="00">Seleccionar...</option>
                              {dataTipoTarea.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Estado
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6" hidden>
                            <select
                              disabled="true"
                              className="form-select"
                              name="bTar_Estado"
                              value={getTareas && getTareas.bTar_Estado}
                              onChange={handleChange}
                            >
                              <option value="00">Seleccionar...</option>
                              <option value="1">Activo</option>
                              <option value="0">No activo</option>
                            </select>
                            <label className="col-form-label labelDown">
                              Estado
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 row" style={{ paddingRight: '12px', paddingLeft: '12px' }}>
                        <div className="col-md-12">
                          <CardBody style={{ paddingLeft: '0px', paddingBottom: '0px' }}>

                            <MDBDataTable
                              data={badgesData2}
                              className="TablaListas"
                              responsiveXl
                              fixed
                              hover
                              entriesLabel="Mostrar"
                              entrylabel={"Mostrar entradas"}
                              infoLabel={["Mostrando ", "a", "de", "registros"]}
                              bordered
                              order={["age", "desc"]}
                              noRecordsFoundLabel={"No hay datos"}
                              searchLabel={"Buscar"}
                              noBottomColumns
                              paginationLabel={["Anterior", "Siguiente"]}
                              theadColor="HeadBlue"
                              searching={false}
                              paging={false}
                              info={false}
                            />
                          </CardBody>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(3)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                      </div>
                    </Modal>
                    <Modal size="lg" isOpen={modalFiltro}>
                      <div
                        className="modal-header ModalHeaderColor"
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Seleccione criterio(s) de búsqueda
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(4)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <strong>Fechas (rango)</strong>
                        <hr style={{ color: "#000", marginTop: "0px" }} />
                        <div className="mb-3 row">
                          <div className="col-md-1" style={{ width: "20px" }}></div>
                          <div className="col-md-5">
                            <input
                              className="form-control inputDown"
                              type="date" name="FechaInicial" value={getFiltro && getFiltro.FechaInicial} onChange={handleChangeFiltro}
                            />
                            <label className="col-form-label labelDown">
                              Inicio*
                            </label>
                          </div>
                          <div className="col-md-1" style={{ width: "20px" }}></div>
                          <div className="col-md-5">
                            <input
                              className="form-control inputDown"
                              type="date" name="FechaFin" value={getFiltro && getFiltro.FechaFin} onChange={handleChangeFiltro}
                            />
                            <label className="col-form-label labelDown">
                              Fin*
                            </label>
                          </div>
                        </div>
                        <hr style={{ color: "#000", marginTop: "0px" }} />
                        <div className="mb-3 row">
                          <div className="col-md-1" style={{ width: "20px" }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={chekedValor1}
                              value={chekedValor1}
                              onChange={handleChangeValor1}
                              style={{ transform: "scale(1.5)", marginTop: "10px" }}
                            />
                          </div>
                          <div className="col-md-5">
                            <select className="form-select inputDown" disabled={Valor1} name="nCli_Id" id="nCli_Id" onChange={handleChangeFiltro}>
                              <option value="00">Seleccionar...</option>
                              {dataCliente.map((row, i) => (
                                <option key={i} value={row.nCli_Id}>
                                  {row.cCli_NombreCompleto}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Cliente
                            </label>
                          </div>
                          <div className="col-md-1" style={{ width: "20px" }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={chekedValor2}
                              value={chekedValor2}
                              onChange={handleChangeValor2}
                              style={{ transform: "scale(1.5)", marginTop: "10px" }}
                            />
                          </div>
                          <div className="col-md-5">
                            <select
                              className="form-select inputDown"
                              id="nCas_Id" name="nCas_Id" disabled={Valor2} onChange={handleChangeFiltro}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCaso.map((row, i) => (
                                <option key={i} value={row.nCas_Id}>
                                  {row.cCas_Titulo}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Caso
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-1" style={{ width: "20px" }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={chekedValor5}
                              value={chekedValor5}
                              onChange={handleChangeValor5}
                              style={{ transform: "scale(1.5)", marginTop: "10px" }}
                            />
                          </div>
                          <div className="col-md-5">
                            <select
                              className="form-select"
                              id="nUsu_ID" name="nUsu_ID" disabled={Valor5} onChange={handleChangeFiltro}
                            >
                              <option value="00">Seleccionar...</option>
                              {dataComboUsuario.map((row, i) => (
                                <option key={i} value={row.nUsu_ID}>
                                  {row.cUsu_Nombres}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Usuario
                            </label>
                          </div>
                          <div className="col-md-1" style={{ width: "20px" }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={chekedValor4}
                              value={chekedValor4}
                              onChange={handleChangeValor4}
                              style={{ transform: "scale(1.5)", marginTop: "10px" }}
                            />
                          </div>
                          <div className="col-md-5">
                            <select
                              className="form-select"
                              name="cTar_Tab_Categoria" id="cTar_Tab_Categoria" disabled={Valor4} onChange={handleChangeFiltro}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCategoriaTarea.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Categoria
                            </label>
                          </div>
                        </div>

                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(4)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger waves-effect waves-light"
                          onClick={() => FuncionFiltroTareas(document.getElementById("cTar_Tab_Tipo").value)}
                        >
                          Filtrar
                        </button>
                      </div>
                    </Modal>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody style={{ paddingBottom: "0px" }}>
                  <CardTitle style={{ fontSize: "20px", color: "#aa1b0E", fontWeight: "normal" }}>
                    <span style={{ color: "#aa1b0E" }}> Listado de Tareas{" "}</span>
                  </CardTitle>
                  <div className="mb-3 row">
                    <div className="col-md-2">
                      <input
                        type="checkbox"
                        className="checkbox"
                        id="selecionarMasivo"
                        style={{ marginTop: "8px", paddingLeft: '0px', marginLeft: '0px', left: '9px' }}
                        onClick={() => SeleccionarTodo()}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customCheckcolor1"
                        style={{ marginTop: "8px", paddingLeft: "50px" }}
                      >
                        Marcar todos
                      </label>
                    </div>
                    <div className="col-md-3">
                    <label className="col-form-label">Fecha de: {getFiltro.FechaInicial} a {getFiltro.FechaFin}  </label>

                    </div>
                    <div className="col-md-3" style={{ textAlign: 'right', float: 'right', marginTop: '-35px' }}>
                      <div className="mb-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <div className="col-md-12" >
                          <label className="col-form-label">Cantidad de tareas : {data.length}</label>
                          <input className="form-control" placeholder="🔎 Ingrese el titulo de la tarea" type="text" onChange={onChangeHandler.bind(this)} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2" style={{ textAlign: 'right', float: 'right', marginTop: '-35px' }}>
                      <div className="mb-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <div className="col-md-12" >
                          <label className="col-form-label">
                            Estado
                          </label>
                          <select
                            className="form-select"
                            name="cTar_Tab_Tipo" id="cTar_Tab_Tipo" disabled={Valor3} onChange={handleChangeFiltro}
                          >
                            <option value={"Todos"}>
                              Todos
                            </option>
                            {dataTipoTarea.map((row, i) => (
                              <option key={i} value={row.cTabCodigo}>
                                {row.cTabNombre}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1" style={{ textAlign: 'right', float: 'right', marginTop: '0px' }}>
                      <div className="dropdown" style={{ marginBottom: '10px', marginLeft: '0px' }}>
                        <button hidden={hiddenBtnEditar} onClick={() => myFunction()} className="drop-button btn btn-danger"
                          style={{ width: '200px' }}>Acciones masivas</button>
                        <div id="myDropdown" className="dropdown-content">
                          <a href="#" onClick={() => abrirCerrarModal(6)}>Cambiar estado</a>
                          <a href="#" onClick={() => abrirCerrarModal(7)}>Desasociar participantes</a>
                          <a href="#" onClick={() => methodEliminarTareasLogicamente()}>Eliminar tareas</a>
                        </div>
                      </div>
                    </div>

                  </div>

                </CardBody>
                <form name="f1" style={{ minHeight: '700px' }}>
                  {data.map((row, i) => (
                    <div key={i} style={{ borderBottom: "1px solid #bbb" }}>
                      <div className="container-fluid" style={{ height: 'auto', padding: '0px' }}>
                        <div className="row col-md-12" style={{ padding: '0px', marginLeft: '2px' }}>

                          <div className="col-md-1" style={{ width: '5%', height: '100px', padding: '0px' }}>
                            <input
                              type="checkbox"
                              className="checkbox"
                              name={JSON.stringify(row)}
                              style={{ paddingLeft: '13px', marginLeft: '23%' }}
                              id={"id" + row.nTar_Id}
                              onClick={() => SelectCheckbox(row)}
                            />
                          </div>
                          <div className="col-md-9" style={{ width: '71%', paddingLeft: '6px' }}>
                            <div className="row col-md-12 titulo Texto-Limit" style={{ marginTop: '10px' }}>
                              <Link className="Texto-Limit col-md-1" data-tip data-for={"tooltip" + row.cCas_Titulo} style={{ width: 'auto', maxWidth: '40%' }} to="/dashboard-Casos" onClick={() => SelectDetalle(row.nCas_Id, row.nCli_Id)} >
                                <a className="" style={{ color: "#6B6B6B" }} >{row.cCas_Titulo}</a>
                                <ReactTooltip id={"tooltip" + row.cCas_Titulo}>
                                  <a style={{ fontSize: '13px', display: 'inline-block', fontWeight: 'normal' }}>Ir al caso</a>
                                </ReactTooltip>
                              </Link>
                              {" "}/{" "}
                              <Link to="#" className="Texto-Limit col-md-5" data-tip data-for={"tooltipEvento"} onClick={() => Acciones(row, "VerDetalle")} >
                                <a className="" style={{ color: "#032D5E" }} >{row.cTar_Titulo}</a>{"     "}
                                <ReactTooltip id={"tooltipEvento"}>
                                  <a style={{ fontSize: '13px', display: 'inline-block', fontWeight: 'normal' }}>Ver el detalle de la tarea</a>
                                </ReactTooltip>
                              </Link>
                            </div>
                            <div className="titulo Texto-Limit" style={{ marginTop: '2px', height: '15px' }}>
                              <label className="text-detalle Texto-Limit">
                                {(() => {
                                  var Color = ''
                                  switch (row.cTar_Tab_Tipo) {
                                    case 'TT01':
                                      Color = '#1F60AB'
                                      break;
                                    case 'TT02':
                                      Color = '#00A94F'
                                      break;
                                    case 'TT03':
                                      Color = '#000'
                                      break;
                                    case 'TT04':
                                      Color = '#AA1B0E'
                                      break;
                                    default:
                                      Color = '#000'
                                      break;
                                  }
                                  return (
                                    <label style={{ color: Color }} className="text-detalle">
                                      Recordatorio:{" "}
                                      {row.cTar_tab_recordar2} {" "}
                                      {(() => {
                                        if (restaFechas(row.dTar_Fec_Vence) < 0) {
                                          return (
                                            <>
                                            </>
                                          )
                                        } else {
                                          return (
                                            <>
                                              {"/ Faltan " + restaFechas(row.dTar_Fec_Vence) + " dias"}
                                            </>
                                          )
                                        }
                                      })()}
                                    </label>
                                  )
                                })()}
                              </label>
                            </div>
                            <div className="detalle Texto-Limit" style={{ backgroundColor: 'white', marginTop: '2px', paddingTop: '4px' }}>
                              <label htmlFor="" className="text-detalle" style={{ marginRight: '3px', float: 'left', marginTop: '8px' }}>
                                {row.cTar_Tab_Categoria2}
                              </label>
                              <i style={{ fontSize: "15px", float: 'left', marginTop: '8px' }} className="icon-edificio"></i>
                              <label style={{ marginLeft: '7px', marginRight: '7px', float: 'left' }}></label>
                              <label className="text-detalle" style={{ marginRight: '3px', float: 'left', height: '20px', marginTop: '8px' }}>
                                {row.cCli_NombreCompleto}
                              </label>
                              <i style={{ fontSize: "15px", float: 'left', marginTop: '8px', marginRight: '20px' }} className="icon-Persona"></i>
                              {dataParticipantes.map((row2, i2) => {
                                if (row.nTar_Id == row2.nTar_Id) {
                                  return (
                                    <div key={i2} className="col-md-1" style={{ width: "33px", bottom: "12px", marginTop: '0px', paddingTop: "0px", float: 'left' }}>
                                      <div>
                                        <ReactTooltip id={"tooltip" + row2.cUsu_Nombres}>
                                          {row2.cUsu_Nombres}
                                        </ReactTooltip>
                                      </div>
                                      <div data-tip data-for={"tooltip" + row2.cUsu_Nombres}>
                                        <LetteredAvatar
                                          name={row2.cUsu_Nombres}
                                          size={30}
                                          radius={50}
                                          color="#fff"
                                          backgroundColors={arrayWithColors}
                                          style={{ paddingTop: '0px', marginTop: '0px' }}
                                        />
                                      </div>
                                    </div>)
                                }
                              })}
                              <div className="row col-md-12 titulo Texto-Limit" style={{ paddingBottom: '10px', marginTop: '0px', paddingTop: '0px' }}>
                                {row.nfFas_Id !== 0 ? (<a style={{ paddingLeft: "12px", marginTop: '0px', paddingTop: '0px' }}>{row.cfFlu_Nombre} / {row.cfFas_Nombre}</a>) : false}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2" style={{ width: '24%', padding: '0px' }}>
                            <div className="col-md-10 Texto-Limit" style={{ float: 'left', marginTop: '5px' }}>
                              <label className="" style={{ fontSize: '12px' }}>
                                CASO-{row.cCas_Cod_Externo}
                              </label>
                            </div>
                            <div className="" style={{ float: 'left', marginTop: '5px', }}>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="dropdown-toggle">
                                  <i className="bx bx-dots-vertical-rounded" />
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <DropdownItem href="#"
                                    onClick={() =>
                                      Acciones(row, "VerDetalle")}
                                  >
                                    Ver detalle
                                  </DropdownItem>
                                  <DropdownItem hidden={hiddenBtnEditar}
                                    onClick={() => Acciones(row, "Editar")}
                                  >
                                    Editar
                                  </DropdownItem>

                                  <DropdownItem hidden={hiddenBtnEditar}
                                    onClick={() => Acciones(row, "CambiarEstado 1")}
                                  >
                                    Aprobado
                                  </DropdownItem>
                                  <DropdownItem hidden={hiddenBtnEditar}
                                    onClick={() => Acciones(row, "CambiarEstado 2")}
                                  >
                                    Finalizado
                                  </DropdownItem> 
                                  <DropdownItem hidden={hiddenBtnEditar}
                                    onClick={() => Acciones(row, "CambiarEstado 3")}
                                  >
                                    Rechazado
                                  </DropdownItem>

                                  <DropdownItem hidden={hiddenBtnEliminar}
                                    onClick={() =>
                                      Swal.fire({
                                        title: "Mensaje",
                                        html:
                                          "Estas seguro de eliminar la tarea: <b>" +
                                          row.cTar_Titulo +
                                          "</b>",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Aceptar",
                                        cancelButtonText: "Cancelar",
                                      }).then(result => {
                                        if (result.isConfirmed) {
                                          FuncionEliminarTarea(row.nTar_Id)
                                        }
                                      })
                                    }
                                  >
                                    Eliminar
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>

                            </div>
                            <div className="col-md-12 titulo Texto-Limit" style={{ marginTop: '10px' }}>
                              <div className="row col-md-12 active" style={{ marginTop: '0px' }}>
                                <div className="col-md-2" style={{ width: '5%', paddingRight: '0px', marginRight: '0px' }}>
                                  <i style={{ fontSize: "15px", color: "#AA1B0E" }} className="icon-calendario"></i>
                                </div>
                                <div className="col-md-10 Texto-Limit" style={{ width: '80%', paddingRight: '0px', marginRight: '0px', marginTop: '0px' }}>
                                  <label className="text-case" style={{ color: "#AA1B0E" }}>
                                    Vencimiento {row.dTar_Fec_Vence2} / {row.tTar_Hora_Vence2}
                                  </label>
                                </div>
                                <div className="users">
                                  {(() => {
                                    var Color = ''
                                    switch (row.cTar_Tab_Tipo) {
                                      case 'TT01':
                                        Color = '#1F60AB'
                                        break;
                                      case 'TT02':
                                        Color = '#00A94F'
                                        break;
                                      case 'TT03':
                                        Color = '#000'
                                        break;
                                      case 'TT04':
                                        Color = '#AA1B0E'
                                        break;
                                      default:
                                        Color = '#000'
                                        break;
                                    }
                                    return (
                                      <FontAwesomeIcon
                                        style={{ color: Color }}
                                        icon={faCircle}
                                      />
                                    )
                                  })()}{" "}
                                  <label className="text-case">
                                    {row.cTar_Tab_Tipo2}
                                  </label>
                                  {(() => {
                                    return (
                                      <label>{validarFecha(row.dTar_Fec_Vence, row.cTar_tab_recordar, row.cTar_Tab_Tipo)}

                                      </label>
                                    )
                                  })()}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Tareas
